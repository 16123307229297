import { LOADING_DATA_USER_PROFILE, LOADING_SEND_USER_PROFILE } from "../action-reducer-type";

const defaultState = {
  loadingPage: false,
  loadingSend: false,
  restData: {},
  code: 500,
  message: "",
};

let response = {};
const Profile_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_PROFILE_USER":
      response = {
        restData: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_PROFILE_USER":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_PROFILE_USER":
      response = {
        ...state,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    case LOADING_DATA_USER_PROFILE:
      response = {
        ...state,
        loadingPage: action.data || false
      }
      break;
    case LOADING_SEND_USER_PROFILE:
      response = {
        ...state,
        loadingSend: action.data || false
      }
      break;
    default:
      return {
        ...state,
      };
  }

  return response;
};

export default Profile_lib;
