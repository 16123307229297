
const defaultState = {
    loadingPage: true,
    loadingSend: true,
    restData: [],
    code: 500,
    message: "",
};

let response = {};
const PaymentGateway_lib = (state = defaultState, action = {}) => {
    switch (action.type) {
        case "SUCCESS_PAYMENT_GATEWAYS":
            response = {
                ...state,
                restData: action.data ? action.data : [],
                code: action.code ? action.code : 500,
                loadingPage: false,
                loadingSend: false,
                message: action.message ? action.message : "",
            };
            break;
        case "ERROR_PAYMENT_GATEWAYS":
            response = {
                ...state,
                message: action.message ? action.message : "",
                code: action.code ? action.code : 500,
            };
            break;
        default:
            return state;
    }

    return response;
};

export default PaymentGateway_lib
