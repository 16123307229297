const defaultState = {
  loadingPage: false,
  loadingSend: false,
  restData: [],
  code: 500,
  message: "",
  modalFormIsOpen: false,
  modalFormData: {},
  reload: false,
};

let response = {};
const Apps_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_APPS":
      response = {
        ...state,
        restData: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };
      break;
    case "ERROR_APPS":
      response = {
        ...state,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    case "SET_DATA_APPS":
      response = {
        ...state,
        restData: action.data ? action.data : [],
        reload: false,
        code: action.code || 500
      };
      break;
    case "SET_LOADING_APPS":
      response = {
        ...state,
        loadingPage: action.data || false
      }
      break;
    case "SET_SEND_APPS":
      response = {
        ...state,
        loadingSend: action.data || false
      }
      break;
    case "SHOW_MODAL":
      response = {
        ...state,
        modalFormIsOpen: true,
        modalFormData: action.data ? action.data : {},
      };
      break;
    case "CLOSE_MODAL":
      response = {
        ...state,
        modalFormIsOpen: false,
        reload: action.reload ? action.reload : false,
      };
      break;
    default:
      return state;
  }
  return response;
};

export default Apps_lib;
