import { CONNECTION } from "../../config/Connection";
import { CONFIG_, __userID } from "../../config/Config";

var get = "apps";
var post = "app";
var isLive = true;
const path = process.env.REACT_APP_PATH_APPS;
export const __getDataApp = () => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get,
      method: "GET",
      path: path,
      live: isLive,
      headers: {
        session_id: __userID(),
      },
    });
    dispatch({ type: "LOADING_APP" });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: "SUCCESS_APP",
            message: "Success get data",
            data: data,
            code: code,
          });
        }
        return dispatch({ type: "ERROR_APP", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_APP", message: "Network error.." });
      });
  };
};

export const __postDataApp = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_APP" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          return dispatch({
            type: "SUCCESS_APP",
            message: "Success post data",
          });
        }
        return dispatch({ type: "ERROR_APP", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_APP", message: "Network error.." });
      });
  };
};

export const __updateDataApp = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_APP" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: "SUCCESS_APP", message: "Success put data" });
        }
        return dispatch({ type: "ERROR_APP", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_APP", message: "Network error.." });
      });
  };
};

export const __detailDataApp = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_APP" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: "SUCCESS_APP", message: "Success get data" });
        }
        return dispatch({ type: "ERROR_APP", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_APP", message: "Network error.." });
      });
  };
};

export const __deleteDataApp = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_APP" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: "SUCCESS_APP", message: "Success get data" });
        }
        return dispatch({ type: "ERROR_APP", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_APP", message: "Network error.." });
      });
  };
};
