const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: {},
  code: 0,
  message: "",
  permissions: {},
};

let response = {};
const Auth_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_AUTH":
      const { data } = action;
      if (data) {
        _setAdmin("admin_status", action.code);
        _setAdmin("session_id", data.session_id);
      }
      response = {
        ...state,
        code: action.code ? action.code : 500,
        message: action.message ? action.message : "Message not found",
        loadingPage: false,
        loadingSend: false,
      };
      break;
    case "LOADING_AUTH":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };

      break;
    case "ERROR_AUTH":
      response = {
        ...state,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
      };
      break;
    case "SET_PERMISSIONS":
      response = {
        ...state,
        permissions: action.data ? action.data : {},
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
      break;
  }

  return response;
};

const _setAdmin = (key, value) => {
  return localStorage.setItem(key, value);
};

export default Auth_lib;
