import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';

const PATH = process.env.REACT_APP_PATH_USER;
var get = '/user-have-access-to-customer-db-module';
var post = '';
export const __getDataModulAccess = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: 'GET', path: PATH });
    dispatch({ type: 'LOADING_MODULACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_MODULACCESS',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_MODULACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MODULACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __postDataModulAccess = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST' });
    dispatch({ type: 'LOADING_MODULACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          return dispatch({
            type: 'SUCCESS_MODULACCESS',
            message: 'Success post data',
          });
        }
        return dispatch({ type: 'ERROR_MODULACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MODULACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __updateDataModulAccess = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, data: params, method: 'PUT' });
    dispatch({ type: 'LOADING_MODULACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_MODULACCESS',
            message: 'Success put data',
          });
        }
        return dispatch({ type: 'ERROR_MODULACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MODULACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __detailDataModulAccess = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_MODULACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_MODULACCESS',
            message: 'Success get data',
          });
        }
        return dispatch({ type: 'ERROR_MODULACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MODULACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __deleteDataModulAccess = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'DELETE' });
    dispatch({ type: 'LOADING_MODULACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_MODULACCESS',
            message: 'Success get data',
          });
        }
        return dispatch({ type: 'ERROR_MODULACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MODULACCESS',
          message: 'Network error..',
        });
      });
  };
};
