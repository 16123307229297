const defaultState = {
    restData: [],
    modalFormIsOpen: false,
    modalFormData: {},
    reload : false,
    isLoading: false,
    isLoaded: false,
    isSending: false,
}

const AppMenus_lib = (state = defaultState, action = {}) => {
    let response;
    switch(action.type){
        case 'SET_MENUS':
            response = {
                ...state,
                restData: action.data ? action.data : [],
                reload : false
            }
            break;
        case 'SHOW_MODAL_MENU':
            response = {
                ...state,
                modalFormIsOpen: true,
                modalFormData: action.data ? action.data : {}
            }
            break;
        case 'CLOSE_MODAL_MENU':
            response = {
                ...state,
                modalFormIsOpen: false,
                reload : action.reload ? action.reload : false
            }
            break;
        case 'SET_LOADING_MENU':
            response = {
                ...state,
                isLoading: action.data || false
            }
            break;
        case 'SET_SEND_MENU':
            response = {
                ...state,
                isSending: action.data || false
            }
            break;
        default:
            response = {
                ...state
            }
    }
    // console.log('response menu', response);
    return response;
}

export default AppMenus_lib;