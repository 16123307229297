import React from "react";
import ruth from "./Ruth.json";
import { Base_www } from "./Helper";
import { GetAppRoutes } from "../models/apps/AppRoutes_act";

const getRouteFromService = async () => {
  let routes = [];
  try {
    routes = await GetAppRoutes();
  } catch (errRouter) {
    alert("Router Error" + JSON.stringify(errRouter));
    console.log("error get router", errRouter);
  }
  // console.log('result routes ', routes);

  var router = [];
  ruth["router"].map((items, i) => {
    return router.push({
      path: Base_www(items.path),
      exact: items.exact,
      layout: items.layout
        ? React.lazy(() => import(`../layout/${items.layout}`))
        : null,
      component: items.component
        ? React.lazy(() => import(`../pages/${items.component}`))
        : null,
      name: items.name,
      role: items.role ? items.role : "All access",
    });
  });
  if (routes && routes.data) {
    routes.data.map((items, i) => {
      return router.push({
        path: Base_www(items.path),
        exact: items.exact,
        layout: items.layout
          ? React.lazy(() => import(`../layout/${items.layout}`))
          : null,
        component: items.component
          ? React.lazy(() => import(`../pages/${items.component}`))
          : null,
        name: items.name,
        role: items.role ? items.role : "All access",
      });
    });
  }
  return router;
};

// export default router;
export default getRouteFromService;
