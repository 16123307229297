import { CONFIG_ } from "../../config/Config";
import { CONNECTION } from "../../config/Connection";

const PATH = process.env.REACT_APP_PATH_AUTH;
const appName = process.env.REACT_APP_NAME;
const get_permission = PATH + "permission-modules/" + appName;

export const GetPermissionBySessionID = (sessionID, roleID) => {
  let config = CONFIG_({
    url: get_permission+"/"+roleID,
    method: "GET",
    headers: {
      session_id: sessionID,
    },
  });
  return CONNECTION(config);
};
