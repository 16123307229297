const initialState = {
    data: [],
    status: 'pending'
}

export const ActionTypes = {
    LOADING : 'LOADING_APP_USERS',
    SUCCESS : 'SUCCESS_APP_USERS',
    ERROR : 'ERROR_APP_USERS'
}

const AppUsersReducer = (state=initialState, action) => {
    let newState = {...state};
    switch(action.type){
        case ActionTypes.LOADING:
            newState.status = 'loading';
            break;
        case ActionTypes.SUCCESS:
            newState.status = 'success';
            newState.data = action.payload.data || [];
            break;
        case ActionTypes.ERROR:
            newState.status = 'error';
            newState.data = [];
            newState.error = action.payload.error;
            break;
        default:
            break;
    }
    return newState;
}

export default AppUsersReducer;