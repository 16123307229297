const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restDataFraudInformation: [],
  code: 500,
  message: "",
};

let response = {};
const Fraudinformation_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_FRAUDINFORMATION_FRAUD":
      response = {
        ...state,
        restDataFraudInformation: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_FRAUDINFORMATION_FRAUD":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_FRAUDINFORMATION_FRAUD":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Fraudinformation_lib;
