export const __openModal = (data) => {
  return (dispatch) => {
    return dispatch({
      type: data.type ? data.type : 'MODAL_SUCCESS',
      modal: data.modal,
      open: data.open,
      data: data.data ? data.data : {},
    });
  };
};
