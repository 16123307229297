// IDEA: Toast library
// IDEA: https://jossmac.github.io/react-toast-notifications/

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const notifSuccess = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const notifDetault = (message) => {
  toast(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const notifWarning = (message) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
export const notifDanger = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
