import { SUCCESS_PAYMENT_REQUEST, ERROR_PAYMENT_REQUEST, SHOW_MODAL_PAYMENT_REQUEST, CLOSE_MODAL_PAYMENT_REQUEST, SEND_DATA_PAYMENT_REQUEST, LOADING_DATA_PAYMENT_REQUEST } from "../action-reducer-type";

const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: [],
  code: 500,
  message: "",
  modalIsOpen: false
};

let response = {};
const PaymentRequest_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case LOADING_DATA_PAYMENT_REQUEST:
      response = {
        ...state,
        loadingPage: true
      }
      break;
    case SEND_DATA_PAYMENT_REQUEST:
      response = {
        ...state,
        loadingSend : true
      }
      break;
    case SUCCESS_PAYMENT_REQUEST:
      response = {
        ...state,
        restData: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };
      break;
    case ERROR_PAYMENT_REQUEST:
      response = {
        ...state,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false
      };
      break;
    case SHOW_MODAL_PAYMENT_REQUEST:
      response = {
        ...state,
        modalIsOpen: true,
        modalFormData: action.data,
        loadingSend: false
      }
      break;
    case CLOSE_MODAL_PAYMENT_REQUEST:
      response = {
        ...state,
        modalIsOpen: false,
        loadingSend: false,
      }
      break;
    default:
      return state;
  }
  return response;
};

export default PaymentRequest_lib
