const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restDataGeneralConf: [],
  code: 500,
  message: "",
};

let response = {};
const Generalconf_lib = (state = defaultState, action = {}) => {
  
  switch (action.type) {
    case "SUCCESS_GENERALCONF_PARTNERSHIPS":
      response = {
        ...state,
        restDataGeneralConf: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_GENERALCONF_PARTNERSHIPS":
      response = {
        ...state,
        loadingPage: true,
        code: action.code ? action.code : 500,

        loadingSend: true,
      };
      break;
    case "ERROR_GENERALCONF_PARTNERSHIPS":
      response = {
        ...state,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
      };
      break;
    default:
    response = {
      ...state,
    }
    break
  }

  return response;
};

export default Generalconf_lib;
