import axios from 'axios';
var jwt_strapi = '';
const strapi_endpoint = 'https://content.giftano.com/';

export const CONNECTION = (config) => {
  return axios({ ...config })
    .then((response) => {
      const { code } = response.data;
      if (code === 200 || code === 201) {
        return response.data;
      }
      return response.data;
    })
    .catch((err) => {
      console.error(err.response);
      if (err.response && err.response.status === 401) {
        return {
          code: 401,
          message: 'Your session is invalid/Expired, please login again!',
        };
      } else {
        return { code: 500, message: 'Network error!!' };
      }
    });
};

export const STRAPI = async (id, method, data = {}, headers = {}) => {
  // console.log('current_jwt before login', jwt_strapi);
  if (jwt_strapi === '') {
    await loginStrapi();
  }
  // console.log('current_jwt after login', jwt_strapi);
  try {
    let res = await axios({
      method: method,
      url: strapi_endpoint + id,
      data: data,
      headers: {
        Authorization: 'Bearer ' + jwt_strapi,
        ...headers,
      },
    });
    return res;
  } catch (err) {
    console.log('error found', err);
    return {};
  }
};

const loginStrapi = async () => {
  console.log('login get jwt');
};
