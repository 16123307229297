const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restDataWhiteLabelTheme: [],
  code: 500,
  message: "",
};

let response = {};
const WhitelabelThemes_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_WHITELABELTHEMES_WHITELABEL":
      response = {
        ...state,
        restDataWhiteLabelTheme: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_WHITELABELTHEMES_WHITELABEL":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_WHITELABELTHEMES_WHITELABEL":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingSend: false,
      };
  }

  return response;
};

export default WhitelabelThemes_lib;
