import { CONNECTION } from "../../config/Connection";
import { CONFIG_ } from "../../config/Config";

const PATH = process.env.REACT_APP_PATH_USER;
const get = PATH + "roles";
const post = PATH + "role";

export const __getDataManagerole = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_MANAGEROLE_APPUSERMANAGEMENT" });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: "SUCCESS_MANAGEROLE_APPUSERMANAGEMENT",
            message: "Success get data",
            data: data,
          });
        }
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "Network error..",
        });
      });
  };
};

export const __postDataManagerole = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_MANAGEROLE_APPUSERMANAGEMENT" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          return dispatch({
            type: "SUCCESS_MANAGEROLE_APPUSERMANAGEMENT",
            message: "Success post data",
          });
        }
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "Network error..",
        });
      });
  };
};

export const __updateDataManagerole = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_MANAGEROLE_APPUSERMANAGEMENT" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: "SUCCESS_MANAGEROLE_APPUSERMANAGEMENT",
            message: "Success put data",
          });
        }
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "Network error..",
        });
      });
  };
};

export const __detailDataManagerole = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_MANAGEROLE_APPUSERMANAGEMENT" });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: "DETAIL_MANAGEROLE_APPUSERMANAGEMENT",
            message: "Success get data",
            data: data,
            code: code,
          });
        }
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "failed",
          code: code,
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "Network error..",
        });
      });
  };
};

export const __deleteDataManagerole = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_MANAGEROLE_APPUSERMANAGEMENT" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: "SUCCESS_MANAGEROLE_APPUSERMANAGEMENT",
            message: "Success get data",
          });
        }
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_MANAGEROLE_APPUSERMANAGEMENT",
          message: "Network error..",
        });
      });
  };
};
