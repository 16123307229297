import { CONNECTION } from '../../config/Connection';
import { CONFIG_, __userID } from '../../config/Config';
import { notifDanger, notifSuccess } from '../../component/notif/Notification';
import { __openModal } from '../modal/Modal_act';
import { LOADING_SEND_USER_PROFILE } from '../action-reducer-type';
import { toast } from 'react-toastify';

var get = 'get';
var post = 'post';
var isLive = true;
const PATH = process.env.REACT_APP_PATH_PROFILES;
const APPNAME = process.env.REACT_APP_NAME;
export const __getDataProfile = (params) => {
  const { id } = params;
  return (dispatch) => {
    let config = CONFIG_({
      url: get + '?userid=' + id,
      method: 'GET',
      path: PATH,
      live: isLive,
      headers: { session_id: __userID() },
    });
    dispatch({ type: 'LOADING_PROFILE_USER' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_PROFILE_USER',
            message: 'Success get data',
            data: data,
            code: code,
          });
        }
        return dispatch({ type: 'ERROR_PROFILE_USER', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILE_USER',
          message: 'Network error..',
        });
      });
  };
};

export const __postDataProfile = (params) => {
  return (dispatch) => {
    const { user_id } = params;
    let config = CONFIG_({
      url: post,
      data: params,
      method: 'POST',
      path: PATH,
      live: isLive,
    });
    dispatch({ type: 'LOADING_PROFILE_USER' });
    CONNECTION(config)
      .then((response) => {
        const { code, message } = response;
        if (code === 201) {
          dispatch(
            __openModal({ modal: 'ADD_PROFILEAPPLICATION', open: false })
          );

          notifSuccess('Profile application is created');
          return dispatch(__getDataProfile({ id: user_id }));
        }
        notifDanger(message);
        return dispatch({
          type: 'ERROR_PROFILE_USER',
          message: 'failed',
          code: code,
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILE_USER',
          message: 'Network error..',
        });
      });
  };
};

export const __updateDataProfile = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, data: params, method: 'PUT' });
    dispatch({ type: 'LOADING_PROFILE_USER' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_PROFILE_USER',
            message: 'Success put data',
          });
        }
        return dispatch({ type: 'ERROR_PROFILE_USER', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILE_USER',
          message: 'Network error..',
        });
      });
  };
};

export const __detailDataProfile = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_PROFILE_USER' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_PROFILE_USER',
            message: 'Success get data',
          });
        }
        return dispatch({ type: 'ERROR_PROFILE_USER', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILE_USER',
          message: 'Network error..',
        });
      });
  };
};

export const ActionDeleteProfile = (params) => async (dispatch) => {
  const { id } = params;
  dispatch({ type: LOADING_SEND_USER_PROFILE, data: true });
  try {
    let config = CONFIG_({ url: PATH + 'delete/' + id, method: 'DELETE' });
    let resp = await CONNECTION(config);
    if (resp.code === 200) {
      toast.success('Delete profile success');
      dispatch(__getDataProfile({ id: params.user_id }));
    } else {
      toast.warning(resp.message || 'Error Delete Profile');
    }
  } catch (err) {
    console.log('error delete profile', err);
  }
  dispatch({ type: LOADING_SEND_USER_PROFILE, data: false });
};

// get user profile and role id

export const getUserProfile = (session_id) => {
  const config = CONFIG_({
    url: PATH + 'get/' + APPNAME,
    headers: {
      session_id: session_id,
    },
  });
  return CONNECTION(config);
};
