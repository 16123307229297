import { CONNECTION } from '../../config/Connection';
import { CONFIG_, CONFIG_CP } from '../../config/Config';
import { notifSuccess, notifWarning } from '../../component/notif/Notification';
import { __openModal } from '../modal/Modal_act';
import { objectToQuery } from '../../config/Helper';

var get = 'get?';
var post = '';
var isLive = false;
var PATH = process.env.REACT_APP_PATH_OPTION;
export const __getDataOptionList = (name) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + objectToQuery({ option_names: name }),
      method: 'GET',
      path: PATH,
    });
    dispatch({ type: 'LOADING_OPTIONLIST_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_OPTIONLIST_GENERALSETTING',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __postDataOptionList = (params) => {
  return (dispatch) => {
    const { name } = params;
    let config = CONFIG_({
      url: 'create',
      data: params,
      method: 'POST',
      path: PATH,
    });
    dispatch({ type: 'LOADING_OPTIONLIST_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          dispatch(__openModal({ modal: 'FORM_GENERATE' }));
          notifSuccess('Setting has been create!');
          return dispatch(__getDataOptionList(name));
        }
        notifWarning('Setting fail to create');

        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __updateDataOptionList = (params) => {
  return (dispatch) => {
    let data = new FormData();
    const { name, optionid } = params;
    let keys = Object.keys(params);
    for (let i = 0; i < Object.keys(params).length; i++) {
      data.append(keys[i], params[keys[i]]);
    }
    let config = CONFIG_({
      url: 'update/' + name + '/' + optionid,
      data: params,
      method: 'PUT',
      path: PATH,
    });
    dispatch({ type: 'LOADING_OPTIONLIST_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          dispatch(__openModal({ modal: 'FORM_GENERATE_UPDATE' }));
          notifSuccess('Setting has been updated!');
          return dispatch(__getDataOptionList(name));
        }
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __detailDataOptionList = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_OPTIONLIST_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_OPTIONLIST_GENERALSETTING',
            message: 'Success get data',
          });
        }
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __deleteDataOptionList = (params) => {
  return (dispatch) => {
    const { optionid, slug } = params;
    let data = new FormData();

    data.append('name', slug);
    data.append('optionid', optionid);
    let config = CONFIG_({
      url: 'delete/' + slug + '/' + optionid,
      method: 'DELETE',
      data: data,
      path: PATH,
    });
    dispatch({ type: 'LOADING_OPTIONLIST_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Setting has been deleted!');
          return dispatch(__getDataOptionList(slug));
        }
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_OPTIONLIST_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const manualGetOptions = ({ name }) => {
  let config = CONFIG_({
    url: get + objectToQuery({ option_names: name }),
    method: 'GET',
    path: PATH,
  });
  return CONNECTION(config);
};
