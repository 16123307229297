import { SET_MODULES, SHOW_MODAL_MODULES, CLOSE_MODAL_MODULE } from "../action-reducer-type";

const defaultState = {
    restData: null,
    modalFormIsOpen: false,
    modalFormData: {},
    reload : false,
}

const AppModules_lib = (state = defaultState, action = {}) => {
    let response;
    switch(action.type){
        case SET_MODULES:
            response = {
                ...state,
                restData: action.data ? action.data : [],
                reload : false
            }
            break;
        case SHOW_MODAL_MODULES:
            response = {
                ...state,
                modalFormIsOpen: true,
                modalFormData: action.data ? action.data : {}
            }
            break;
        case CLOSE_MODAL_MODULE:
            response = {
                ...state,
                modalFormIsOpen: false,
                reload : action.reload ? action.reload : false
            }
            break;
        default:
            response = {
                ...state
            }
    }
    return response;
}

export default AppModules_lib;