

const initialState = {
    data: [],
    status: 'pending',
    modalCreateOpen: false,
    modalEditOpen: false,
    modalEditData: {}
}

export const ActionTypes = {
    LOADING: 'LOADING_INTERNAL_USER',
    SUCCESS: 'SUCCESS_INTERNAL_USER',
    ERROR: 'ERROR_INTERNAL_USER',
    MODAL_CREATE: 'MODAL_CREATE_INTERNAL_USER',
    MODAL_EDIT: 'MODAL_EDIT_INTERNAL_USER'
}

const InternalUserReducer = (state=initialState, action) => {
    let newState = {...state};
    switch(action.type){
        case ActionTypes.LOADING:
            newState.status = 'loading';
            break;
        case ActionTypes.SUCCESS:
            newState.data = action.payload.data || [];
            newState.status = 'success';
            break;
        case ActionTypes.ERROR:
            newState.status = 'error';
            newState.data = []
            break;
        case ActionTypes.MODAL_CREATE:
            newState.modalCreateOpen = action.payload.open || false;
            break;
        case ActionTypes.MODAL_EDIT:
            newState.modalEditOpen = action.payload.open || false;
            newState.modalEditData = action.payload.data || {};
            break;
        default:
            break;
    }
    return newState;
}

export default InternalUserReducer;