import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';
import { notifSuccess } from '../../component/notif/Notification';
import { objectToQuery } from '../../config/Helper';

const path = process.env.REACT_APP_PATH_WHITELABEL;
var post = '';
export const __getDataWhitelabelSchema = (params = {}) => {
  return (dispatch) => {
    let query = objectToQuery(params ? params : { limit: 10, page: 1 });
    let config = CONFIG_({ url: 'form-schema/get?' + query, method: 'GET', path: path });
    dispatch({ type: 'LOADING_WHITELABELSCHEMA_WHITELABEL' });
    CONNECTION(config)
      .then((response) => {
        const { code, data, pagination_info } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_WHITELABELSCHEMA_WHITELABEL',
            message: 'Success get data',
            data: data,
            pagination_info: pagination_info,
          });
        }
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'Network error..' });
      });
  };
};

export const __postDataWhitelabelSchema = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: 'form-schema/create', path: path, data: params, method: 'POST' });
    dispatch({ type: 'LOADING_WHITELABELSCHEMA_WHITELABEL' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Whitelabel Schema has been added');
          return dispatch(__getDataWhitelabelSchema());
        }
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'Network error..' });
      });
  };
};

export const __updateDataWhitelabelSchema = (params, pagination) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({
      url: 'form-schema/update/' + id,
      data: params,
      method: 'PUT',
      path: path,
    });
    dispatch({ type: 'LOADING_WHITELABELSCHEMA_WHITELABEL' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Whitelabel Schema has been updated');
          return dispatch(__getDataWhitelabelSchema(pagination));
        }
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'Network error..' });
      });
  };
};

export const __detailDataWhitelabelSchema = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_WHITELABELSCHEMA_WHITELABEL' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Whitelabel Schema has been deleted');
          return dispatch(__getDataWhitelabelSchema());
        }
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'Network error..' });
      });
  };
};

export const __deleteDataWhitelabelSchema = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({
      url: 'form-schema/delete/' + id,
      method: 'DELETE',
      path: path,
    });
    dispatch({ type: 'LOADING_WHITELABELSCHEMA_WHITELABEL' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Whitelabel Schema has been deleted');
          return dispatch(__getDataWhitelabelSchema());
        }
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_WHITELABELSCHEMA_WHITELABEL', message: 'Network error..' });
      });
  };
};
