import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';
import { notifSuccess } from '../../component/notif/Notification';
import { __openModal } from '../modal/Modal_act';

var get = 'access/get/';
var post = 'access/create';
var isLive = false;
var path = 'profile/';
export const __getDataProfileAccess = ({ profileId = '' }) => {
  return (dispatch) => {
    console.log(profileId);
    let config = CONFIG_({
      url: get + 'all?field=ProfileID&value=' + profileId,
      method: 'GET',
      path: path,
    });
    dispatch({ type: 'LOADING_PROFILEACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_PROFILEACCESS',
            message: 'Success get data',
            data: data,
            code: 200,
          });
        }
        return dispatch({ type: 'ERROR_PROFILEACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILEACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __postDataProfileAccess = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: 'POST',
      path: path,
    });

    const { profile_id } = params;
    dispatch({ type: 'LOADING_PROFILEACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          notifSuccess('Profile access has been create');
          dispatch(__getDataProfileAccess({ profileId: profile_id }));
          dispatch(
            __openModal({ modal: 'MODAL_ADDPROFILEACCESS', open: false })
          );
          return dispatch({
            type: 'SUCCESS_PROFILEACCESS',
            message: 'Success post data',
          });
        }
        return dispatch({ type: 'ERROR_PROFILEACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILEACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __updateDataProfileAccess = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, data: params, method: 'PUT' });
    dispatch({ type: 'LOADING_PROFILEACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_PROFILEACCESS',
            message: 'Success put data',
          });
        }
        return dispatch({ type: 'ERROR_PROFILEACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILEACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __detailDataProfileAccess = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_PROFILEACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_PROFILEACCESS',
            message: 'Success get data',
          });
        }
        return dispatch({ type: 'ERROR_PROFILEACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILEACCESS',
          message: 'Network error..',
        });
      });
  };
};

export const __deleteDataProfileAccess = (params) => {
  return (dispatch) => {
    const { id, profileId } = params;
    let config = CONFIG_({
      url: '/access/delete/' + id,
      method: 'DELETE',
      path: path,
    });
    dispatch({ type: 'LOADING_PROFILEACCESS' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Profile access has been delete');

          return dispatch(__getDataProfileAccess({ profileId: profileId }));
        }
        return dispatch({ type: 'ERROR_PROFILEACCESS', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_PROFILEACCESS',
          message: 'Network error..',
        });
      });
  };
};
