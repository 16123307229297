import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';

var get = 'get';
var post = '';
var isLive = false;
var path = process.env.REACT_APP_GOOGLE_SCHEDULAR;
export const __getDataSchedular = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: 'GET', path: path });
    dispatch({ type: 'LOADING_SCHEDULAR_GOOGLE' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_SCHEDULAR_GOOGLE',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'Network error..' });
      });
  };
};

export const __postDataSchedular = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST' });
    dispatch({ type: 'LOADING_SCHEDULAR_GOOGLE' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          return dispatch({ type: 'SUCCESS_SCHEDULAR_GOOGLE', message: 'Success post data' });
        }
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'Network error..' });
      });
  };
};

export const __updateDataSchedular = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, data: params, method: 'PUT' });
    dispatch({ type: 'LOADING_SCHEDULAR_GOOGLE' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: 'SUCCESS_SCHEDULAR_GOOGLE', message: 'Success put data' });
        }
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'Network error..' });
      });
  };
};

export const __detailDataSchedular = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_SCHEDULAR_GOOGLE' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: 'SUCCESS_SCHEDULAR_GOOGLE', message: 'Success get data' });
        }
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'Network error..' });
      });
  };
};

export const __deleteDataSchedular = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'DELETE' });
    dispatch({ type: 'LOADING_SCHEDULAR_GOOGLE' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: 'SUCCESS_SCHEDULAR_GOOGLE', message: 'Success get data' });
        }
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SCHEDULAR_GOOGLE', message: 'Network error..' });
      });
  };
};
