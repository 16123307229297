const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restDataTags: [],
  tagParent: [],
  code: 500,
  message: "",
  dataAllTags: [],
};

let response = {};
const Tag_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_TAG_TAGGING":
      response = {
        ...state,
        restDataTags: action.data ? action.data : [],
        tagParent: action.parent ? action.parent : [],
        dataAllTags: action.dataAllTags ? action.dataAllTags : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
      case "SUCCESS_TAG_TAGGING_BY":
        response = {
          ...state,
          restDataTags: action.data ? action.data : [],
          tagParent: action.parent ? action.parent : [],
          code: action.code ? action.code : 500,
          loadingPage: false,
          loadingSend: false,
          message: action.message ? action.message : "",
        };
  
        break;
    case "LOADING_TAG_TAGGING":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_TAG_TAGGING":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Tag_lib;
