import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'pending',
  data: [],
  page: 1,
  limit: 10,
  dateStart: '',
  dateEnd: '',
  voucherStatus: '',
  forceUpdate: false,
};

const GrabOrderHistorySlice = createSlice({
  name: 'grab-order-history',
  initialState,
  reducers: {
    setDateStart: (state, action) => {
      state.dateStart = action.payload;
    },
    setDateEnd: (state, action) => {
      state.dateEnd = action.payload;
    },
    setVoucherStatus: (state, action) => {
      state.voucherStatus = action.payload;
    },
    setForceUpdate: (state, action) => {
      state.forceUpdate = action.payload;
    },
    setLoading: (state) => {
      state.status = 'loading';
    },
    setData: (state, action) => {
      state.data = action.payload.data;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.total = action.payload.total;
      state.dateStart = action.payload.dateStart;
      state.dateEnd = action.payload.dateEnd;
      state.status = 'success';
    },
    setError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
  },
});

export const {
  setDateStart,
  setDateEnd,
  setVoucherStatus,
  setForceUpdate,
  setLoading,
  setData,
  setError,
} = GrabOrderHistorySlice.actions;

export default GrabOrderHistorySlice.reducer;
