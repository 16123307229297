const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restDataTypeContactGroup: [],
  code: 500,
  message: "",
};

let response = {};
const Typeofcontactgroup_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_TYPEOFCONTACTGROUP_CONTACT":
      response = {
      ...state,
        restDataTypeContactGroup: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_TYPEOFCONTACTGROUP_CONTACT":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_TYPEOFCONTACTGROUP_CONTACT":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Typeofcontactgroup_lib;
