const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restDataWhiteLable: [],
  code: 500,
  message: "",
};

let response = {};
const Whitelabel_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_WHITELABEL_PARTNERSHIPS":
      response = {
        ...state,
        restDataWhiteLable: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_WHITELABEL_PARTNERSHIPS":
      response = {
        ...state,
        loadingPage: true,
        code: action.code ? action.code : 404,
        loadingSend: true,
      };
      break;
    case "ERROR_WHITELABEL_PARTNERSHIPS":
      response = {
        ...state,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
      };
      break;
    default:
     response = {
       ...state
     }
     break
  }

  return response;
};

export default Whitelabel_lib;
