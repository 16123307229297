export const Base_www = (string = '') => {
  if (string.indexOf('/www') !== -1) {
    return string.substr(4);
  }

  return string;
};

export const objectToQuery = (data = {}, exceptionKey = '') => {
  let keys = Object.keys(data);
  let param = '';
  for (let i = 0; i < keys.length; i++) {
    let value = data[keys[i]];

    if (value !== undefined) {
      if (value !== '' && keys[i] !== exceptionKey) {
        param += keys[i] + '=' + value + '&';
      }
    }
  }

  return param.substring(0, param.length - 1);
};

export const convert_datetime = (datetime) => {
  var date = new Date(datetime);
  return (
    day(date.getDay()) +
    ', ' +
    date.getDate() +
    ' ' +
    getMonthName(date.getMonth()) +
    ' ' +
    date.getFullYear() +
    ' ' +
    date.toLocaleTimeString()
  );
};
export const convert_date = (datetime) => {
  var date = new Date(datetime);
  return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
};

function day($day) {
  const myday = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  if ($day < 8) {
    return myday[$day];
  }

  return 'No day';
}

function getMonthName($month) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[$month];
}

export const C_date = (datetime) => {
  var date = new Date(datetime);
  return date;
};
export const uc_words = (string = '') => {
  var str = string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });

  return str;
};

export const string_to_slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};
