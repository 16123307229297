import { CONNECTION } from "../../config/Connection";
import { CONFIG_ } from "../../config/Config";
import { notifDanger, notifSuccess, notifWarning } from "../../component/notif/Notification";

const PATH = process.env.REACT_APP_PATH_BUILDER;

const formsets = PATH + 'formsets';
const formset = PATH + 'formset';

export const ActTypeFormsets = {
    SUCCESS : 'SUCCESS_FORMSET',
    ERROR : 'ERROR_FORMSET',
    START_LOADING : 'START_LOADING_FORMSET',
    FINISH_LOADING : 'FINISH_LOADING_FORMSET',
    SUCCESS_CREATE : 'SUCCESS_CREATE_FORMSET',
    MODAL_TOGGLE : 'MODAL_TOGGLE_FORMSET',
    START_SEND : 'START_SEND_FORMSET',
    FINISH_SEND : 'FINISH_SEND_FORMSET'
}

export const GetDataFormsets = () => async (dispatch) => {
    dispatch({type: ActTypeFormsets.START_LOADING});
    let config = CONFIG_({url: formsets, method:'GET'});
    try{
        let result = await CONNECTION(config);
        // console.log('result get formsets', result);
        if(result.code == 200){
            dispatch({type: ActTypeFormsets.SUCCESS, data: result.data});
        }else{
            notifWarning('Error get formsets data, ' + result.message);
        }
    }catch(err){
        notifDanger('Error get formsets data, please check your internet connections!');
    }
    dispatch({type: ActTypeFormsets.FINISH_LOADING});
}

export const CreateFormset = (dataToSend) => async (dispatch) => {
    dispatch({type: ActTypeFormsets.START_SEND});
    let config = CONFIG_({
        url: formset,
        method: "POST",
        data: {...dataToSend, created_by : 'created_by'},
    });
    try{
        let res = await CONNECTION(config)    
        if(res.code === 201){
            notifSuccess('Save formset success');
            dispatch({type: ActTypeFormsets.MODAL_TOGGLE});
            dispatch(GetDataFormsets());
        }else{
            notifWarning('Error save formset, ' + res.message);
        }
    }catch(err) {
        notifDanger('Error save formset, please check your internet connection!');
    }
    dispatch({type: ActTypeFormsets.FINISH_SEND});
}

export const UpdateFormset = (dataToSend) => async (dispatch) => {
    dispatch({type: ActTypeFormsets.START_SEND});
    let config = CONFIG_({
        url : formset + '/' + dataToSend.id,
        method: "PUT",
        data: {...dataToSend, 'id:updated_by' : 'updated_by'}
    });
    try{
        let res = await CONNECTION(config);
        if(res.code === 200){
            notifSuccess('Save formset success');
            dispatch({type: ActTypeFormsets.MODAL_TOGGLE});
            dispatch(GetDataFormsets());
        }else{
            notifWarning('Error save formset, ' + res.message);
        }
    }catch(err){
        notifDanger('Error save formset, please check your internet connection!');
    }
    dispatch({type: ActTypeFormsets.FINISH_SEND});
}

export const DeleteFormset = (id) => async (dispatch) => {
    dispatch({type: ActTypeFormsets.START_LOADING});
    let config = CONFIG_({url : formset + '/' + id, method: 'DELETE', headers: {UserID: 'UserID'}});
    let result = await CONNECTION(config);
    if(result.code == 200){
        dispatch(GetDataFormsets())
    }else{
        notifWarning('Error delete formset data, ' + result.message);
    }
    dispatch({type: ActTypeFormsets.FINISH_LOADING});
}
