import { CONNECTION } from "../../config/Connection";
import { CONFIG_ } from "../../config/Config";
import { notifDetault, notifSuccess } from "../../component/notif/Notification";

const PATH = process.env.REACT_APP_PATH_AUTH;
var post = "logout";
var isLive = true;

export const __postDataLogout = (params) => {
  const { session_id } = params;
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: "POST",
      path: PATH,
      live: isLive,
      headers: {
        session_id: session_id,
      },
    });

    dispatch({ type: "LOADING_LOGOUT" });
    notifDetault("Loading...");
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess("Logout Success");
          return dispatch({
            type: "SUCCESS_LOGOUT",
            message: "Success post data",
            code: code,
          });
        }
        return dispatch({ type: "ERROR_LOGOUT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_LOGOUT", message: "Network error.." });
      });
  };
};
