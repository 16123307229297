export const ENDPOINT = process.env.REACT_APP_ENDPOINT;
export const CUSTOME_ID = 'internal_giftano';
export const ENDPOINT_CP = process.env.REACT_APP_API_INTERNAL;

export const __userID = (key = 'session_id') => {
  return getSessionID(key);
};
export const HEADER = {
  apikey: process.env.REACT_APP_API_KEY,
  // dc: "",
};
export const AUTH = {
  auth: {
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PASSWORD,
  },
};

// IDEA: FALSE IS LOCALHOST, TRUE IS ENDPOINT
// !parseInt(process.env.REACT_APP_API_LIVE)

export const CONFIG_ = ({
  url = '',
  method = 'GET',
  data = {},
  port = '8080',
  live = true,
  headers = {},
  path = '',
}) => {
  if (!live) {
    let endpoint = 'http://localhost:' + port + '/';
    return {
      url: endpoint + url,
      method: method,
      data: data,
      headers: {
        ...HEADER,
        ...headers,
      },
    };
  } else {
    return {
      url: ENDPOINT + path + url,
      data: data,
      method: method,
      headers: {
        ...HEADER,
        ...headers,
      },
      ...AUTH,
    };
  }
};

export const CONFIG_CP = ({ url = '', method = 'GET', data = new FormData(), headers = {} }) => {
  data.append('custom_id', CUSTOME_ID);
  if (data.get('marketCountry') === null) {
    data.append('marketCountry', localStorage.getItem('dc') || '');
  }

  return {
    url: ENDPOINT_CP + url,
    // url : '/internal/' + url,
    method: method,

    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...headers,
    },
  };
};

export const CONFIG_IP = ({ method = 'GET', data = new FormData() }) => {
  return {
    url: 'https://giftano.com/get_client_ip',
    method: method,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};
function getSessionID(key) {
  return localStorage.getItem(key);
}

export const localstorage_session_id = 'session_id';
