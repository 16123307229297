import { SET_USER_PERMISSION, SHOW_MODAL_USER_PERMISSION, CLOSE_MODAL_USER_PERMISSION } from "../action-reducer-type";

const defaultState = {
    restData : [],
    modalFormIsOpen : false,
    modalFormData : {},
    reload : true
}

const UserPermission_lib = (state = defaultState, action = {}) => {
    let response;
    switch(action.type){
        case SET_USER_PERMISSION:
            response = {
                ...state,
                restData : action.data ? action.data : [],
                reload : false
            }
            break;
        case SHOW_MODAL_USER_PERMISSION:
            response = {
                ...state,
                modalFormIsOpen : true,
                modalFormData : action.data ? action.data : {} 
            }
            break;
        case CLOSE_MODAL_USER_PERMISSION:
            response = {
                ...state,
                modalFormIsOpen : false,
                reload : action.reload ? action.reload : false
            }
            break;
        default :
            response = {...state};
    }
    return response;
}

export default UserPermission_lib;