import {
  SET_FAQ_CATEGORIES,
  ERROR_FAQ_CATEGORIES,
  SHOW_MODAL_FAQ_CATEGORIES,
  CLOSE_MODAL_FAQ_CATEGORIES,
  LOADING_DATA_FAQ_CATEGORIES,
  LOADING_SEND_FAQ_CATEGORIES,
} from "../../action-reducer-type";

const defaultState = {
  loadingPage: false,
  loadingSend: false,
  data: [],
  error: null,
  modalFormIsOpen: false,
  modalFormData: {},
};

const FaqCategories_lib = (state = defaultState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_FAQ_CATEGORIES:
      newState.data = action.data ? action.data : [];
      newState.loadingPage = false;
      newState.loadingSend = false;
      newState.error = null;
      newState.lastUpdate = new Date();
      break;
    case ERROR_FAQ_CATEGORIES:
      newState.error = action.error ? action.error : "UNKNOWN ERROR";
      newState.loadingPage = false;
      newState.loadingSend = false;
      break;
    case SHOW_MODAL_FAQ_CATEGORIES:
      newState.modalFormIsOpen = true;
      newState.modalFormData = action.data ? action.data : {};
      break;
    case CLOSE_MODAL_FAQ_CATEGORIES:
      newState.modalFormIsOpen = false;
      newState.modalFormData = {};
      break;
    case LOADING_DATA_FAQ_CATEGORIES:
      newState.loadingPage = true;
      break;
    case LOADING_SEND_FAQ_CATEGORIES:
      newState.loadingSend = true;
      break;
  }
  // console.log('new state faq categories', newState, action);
  return newState;
};

export default FaqCategories_lib;
