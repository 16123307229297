import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';
import { notifSuccess } from '../../component/notif/Notification';
import { __openModal } from '../modal/Modal_act';

var PATH = process.env.REACT_APP_PATH_WEBHOOK_LIST;
var get = 'get';
var post = 'create';
var isLive = false;
export const __getDataProvider = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: 'GET', path: PATH });
    dispatch({ type: 'LOADING_PROVIDER_WEBHOOK' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_PROVIDER_WEBHOOK',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'Network error..' });
      });
  };
};

export const __postDataProvider = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST', path: PATH });
    dispatch({ type: 'LOADING_PROVIDER_WEBHOOK' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Provider has been created');
          dispatch(
            __openModal({
              modal: 'MODAL_ADD_WEBHOOK',
              open: false,
            })
          );
          return dispatch(__getDataProvider());
        }
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'Network error..' });
      });
  };
};

export const __updateDataProvider = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({
      url: 'update/' + id,
      data: params,
      method: 'PUT',
      path: PATH,
    });
    dispatch({ type: 'LOADING_PROVIDER_WEBHOOK' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Provider has been update');
          dispatch(__openModal({ modal: 'MODAL_UPDATE_PROVIDER_WEBHOOK', open: false }));
          return dispatch(__getDataProvider());
        }
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'Network error..' });
      });
  };
};

export const __detailDataProvider = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_PROVIDER_WEBHOOK' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: 'SUCCESS_PROVIDER_WEBHOOK', message: 'Success get data' });
        }
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'Network error..' });
      });
  };
};

export const __deleteDataProvider = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: 'delete/' + id, method: 'DELETE', path: PATH });
    dispatch({ type: 'LOADING_PROVIDER_WEBHOOK' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Provider has been deleted');
          return dispatch(__getDataProvider());
        }
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_PROVIDER_WEBHOOK', message: 'Network error..' });
      });
  };
};
