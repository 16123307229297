const { SET_ROUTERS } = require("../action-reducer-type");

const defaultState = {
    routers : []
}

const Routers_lib = (state = defaultState, action = {})=>{
    let response;
    switch(action.type){
        case SET_ROUTERS:
            response = {...state,
                routers : action.data ? action.data : [] 
            }
            break;
        default:
            response = {...state}
    }
    return response;
}

export default Routers_lib;