
    const defaultState = {
        loadingPage: true,
        loadingSend: true,
        restData: {},
        code: 500,
        message: "",
      };
      
      let response = {};
      const ContactAddress_lib = (state = defaultState, action = {}) => {
        switch (action.type) {
          case "SUCCESS_CONTACTADDRESS_MEMBERSHIPS":
            response = {
              ...state,
              restData: action.data ? action.data : {},
              code: action.code ? action.code : 500,
              loadingPage: false,
              loadingSend: false,
              message: action.message ? action.message : "",
            };
      
            break;
            case "LOADING_CONTACTADDRESS_MEMBERSHIPS":
            response = {
              ...state,
              loadingPage: true,
              loadingSend: true,
            };
            break;
          case "ERROR_CONTACTADDRESS_MEMBERSHIPS":
            response = {
              ...state,
      
              message: action.message ? action.message : "",
              code: action.code ? action.code : 500,
            };
            break;
          default:
            return {
              ...state, 
              loadingPage:false,
              loadingSend:false
            };
        }
      
        return response;
      };
      
      export default ContactAddress_lib
      