import { createSlice } from '@reduxjs/toolkit';

const SupplierGrabSlice = createSlice({
  name: 'supplier-grab',
  initialState: {
    status: 'pending',
    data: [],
  },
  reducers: {
    setLoading: (state) => {
      state.status = 'loading';
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    setError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
  },
});

export const { setLoading, setData, setError } = SupplierGrabSlice.actions;

export default SupplierGrabSlice.reducer;
