import { SHOW_MODAL_ROLE, CLOSE_MODAL_ROLE, SET_DETAIL_ROLE } from "../action-reducer-type";

const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: [],
  detailData: {},
  code: 500,
  message: "",
  modalFormIsOpen: false,
  modalFormData: {}
};

let response = {};
const Role_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_ROLE":
      response = {
        ...state,
        restData: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };
      break;
    case "LOADING_ROLE":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_ROLE":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    
    case SET_DETAIL_ROLE:
      response = {
        ...state,
        detailData : action.data ? action.data : {}
      }
      break;
    case SHOW_MODAL_ROLE:
      response = {
        ...state,
        modalFormIsOpen : true,
        modalFormData : action.data ? action.data : {}
      }
      break;
    case CLOSE_MODAL_ROLE:
      response = {
        ...state,
        modalFormIsOpen : false,
        modalFormData : {}
      }
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }
  return response;
};

export default Role_lib;
