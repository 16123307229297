const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: [],
  code: 500,
  message: '',
  status: 'default',
};

let response = {};
const Schedular_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_SCHEDULAR_GOOGLE':
      response = {
        ...state,
        restData: action.data ? action.data : '',
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        status: 'success',
        message: action.message ? action.message : '',
      };

      break;
    case 'LOADING_SCHEDULAR_GOOGLE':
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
        status: 'loading',
      };
      break;
    case 'ERROR_SCHEDULAR_GOOGLE':
      response = {
        ...state,
        status: 'success',
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Schedular_lib;
