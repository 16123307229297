const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: [],
  code: 500,
  message: "",
  modalFormGeneratorAddIsOpen: false,
  modalFormGeneratorAddData: {},
};

let response = {};
const FormGenerator_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_FORMGENERATOR":
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_FORMGENERATOR":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_FORMGENERATOR":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    case "MODAL_TOGGLE_FORMGENERATOR":
      response = {
        ...state,
        modalFormGeneratorAddIsOpen : !state.modalFormGeneratorAddIsOpen,
        modalFormGeneratorAddData : action.data ? action.data : {}
      }
      console.log('new response ', response);
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }
  
  return response;
};

export default FormGenerator_lib;
