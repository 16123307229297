const defaultState = {
    restData: [],
    modalFormIsOpen: false,
    modalFormData: {},
    reload : false,
}

const AppRoutes_lib = (state = defaultState, action = {}) => {
    let response;
    switch(action.type){
        case 'SET_ROUTES':
            response = {
                ...state,
                restData: action.data ? action.data : [],
                reload : false
            }
            break;
        case 'SHOW_MODAL_ROUTE':
            response = {
                ...state,
                modalFormIsOpen: true,
                modalFormData: action.data ? action.data : {}
            }
            break;
        case 'CLOSE_MODAL_ROUTE':
            response = {
                ...state,
                modalFormIsOpen: false,
                reload : action.reload ? action.reload : false
            }
            break;
        default:
            response = {
                ...state
            }
    }
    return response;
}

export default AppRoutes_lib;