import { CONFIG_ } from '../../config/Config';
import { CONNECTION } from '../../config/Connection';

const PATH = process.env.REACT_APP_PATH_APPS;

const get = PATH + 'routes';
const post = PATH + 'route';
const idApps = process.env.REACT_APP_ID;

export const GetAppRoutes = (app_id) => {
  const id = app_id === undefined ? idApps : app_id;
  const config = CONFIG_({
    url: get + '/' + id + '?by=app_id',
    method: 'GET',
    live: true,
  });
  return CONNECTION(config);
};
export const CreateRoute = (data) => {
  const dataToSend = {
    ...data,
    created_by: 'string',
  };
  const config = CONFIG_({ url: post, method: 'POST', data: dataToSend });
  return CONNECTION(config);
};
export const UpdateRoute = (id, data) => {
  const dataToSend = {
    ...data,
    'id:updated_by': 'string',
  };
  const config = CONFIG_({
    url: post + '/' + id,
    method: 'PUT',
    data: dataToSend,
  });
  return CONNECTION(config);
};
export const DeleteRoute = (id) => {
  const config = CONFIG_({
    url: post + '/' + id,
    method: 'DELETE',
    headers: { UserID: 'string' },
  });
  return CONNECTION(config);
};
