import {
  SET_FAQS,
  LOADING_DATA_FAQS,
  SHOW_MODAL_FAQS,
  LOADING_SEND_FAQS,
  ERROR_FAQS,
  CLOSE_MODAL_FAQS,
} from "../../action-reducer-type";

const defaultState = {
  loadingPage: false,
  loadingSend: false,
  data: [],
  error: null,
  modalFormIsOpen: false,
  modalFormData: {},
};

const Faq_lib = (state = defaultState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_FAQS:
      newState.loadingPage = false;
      newState.loadingSend = false;
      newState.data = action.data ? action.data : [];
      newState.code = action.code ? action.code : 500;
      newState.lastUpdate = new Date();
      break;
    case ERROR_FAQS:
      newState.loadingPage = false;
      newState.loadingSend = false;
      newState.error = action.error ? action.error : "UNKNOWN ERROR";
      break;
    case LOADING_DATA_FAQS:
      newState.loadingPage = true;
      break;
    case LOADING_SEND_FAQS:
      newState.loadingSend = true;
      break;
    case SHOW_MODAL_FAQS:
      newState.modalFormIsOpen = true;
      newState.modalFormData = action.data ? action.data : {};
      break;
    case CLOSE_MODAL_FAQS:
      newState.modalFormIsOpen = false;
      break;
    default:
      newState = { ...state };
      break;
  }
  return newState;
};

export default Faq_lib;
