const defaultState = {
    data: [],
    isLoading: false,
    isLoaded: false
}

const PermissionMenus_lib = (state = defaultState, action) => {
    let newState = {...state};
    switch(action.type){
        case 'SET_PERMISSION_MENUS':
            newState.data = action.data || [];
            newState.isLoaded = true;
            break;
        case 'SET_LOADING_PERMISSION_MENUS':
            newState.isLoaded = action.isLoading || false;
            break;
        default:
    }
    return newState;
}

export default PermissionMenus_lib;