import {
  SET_ROLE_PERMISSION,
  SHOW_MODAL_ROLE_PERMISSION,
  CLOSE_MODAL_ROLE_PERMISSION,
} from "../action-reducer-type";

const defaultState = {
  restData: [],
  modalFormIsOpen: false,
  modalFormData: {},
  reload: true,
};

const RolePermission_lib = (state = defaultState, action = {}) => {
  let response;
  switch (action.type) {
    case SET_ROLE_PERMISSION:
      response = {
        ...state,
        restData: action.data ? action.data : [],
        reload: false,
      };
      break;
    case SHOW_MODAL_ROLE_PERMISSION:
      response = {
        ...state,
        modalFormIsOpen: true,
        modalFormData: action.data ? action.data : {},
      };
      break;
    case CLOSE_MODAL_ROLE_PERMISSION:
      response = {
        ...state,
        modalFormIsOpen: false,
        reload: action.reload ? action.reload : false,
      };
      break;
    default:
      response = {
        restData: [],
        modalFormIsOpen: false,
        modalFormData: {},
        reload: true,
      };
  }
  return response;
};

export default RolePermission_lib;
