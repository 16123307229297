import { CONNECTION } from '../../config/Connection';
import { __userID, CONFIG_, CONFIG_CP } from '../../config/Config';
import { __openModal } from '../modal/Modal_act';
import { notifSuccess } from '../../component/notif/Notification';

var get = 'internal/braintree/apple-pay/registered-domain/';
var post = 'internal/braintree/apple-pay/register-domain/';
export const __getDataAppleDomain = () => {
  return (dispatch) => {
    const data = new FormData();

    let config = CONFIG_CP({
      url: get,
      method: 'POST',
      data: data,
      headers: { sessionid: __userID('session_id') },
    });
    dispatch({ type: 'LOADING_APPLEDOMAIN_DOMAIN' });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status) {
          return dispatch({
            type: 'SUCCESS_APPLEDOMAIN_DOMAIN',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'Network error..' });
      });
  };
};

export const __postDataAppleDomain = (params) => {
  return (dispatch) => {
    const data = new FormData();
    data.append('domain', params.domain);
    let config = CONFIG_CP({
      url: post,
      data: data,
      method: 'POST',
      headers: { sessionid: __userID('session_id') },
    });
    dispatch({ type: 'LOADING_APPLEDOMAIN_DOMAIN' });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status) {
          notifSuccess('Domain has been created!');
          dispatch(__openModal({ modal: 'MODAL_ADD_DOMAIN', open: false }));
          return dispatch(__getDataAppleDomain());
        }
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'Network error..' });
      });
  };
};

export const __updateDataAppleDomain = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, data: params, method: 'PUT' });
    dispatch({ type: 'LOADING_APPLEDOMAIN_DOMAIN' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: 'SUCCESS_APPLEDOMAIN_DOMAIN', message: 'Success put data' });
        }
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'Network error..' });
      });
  };
};

export const __detailDataAppleDomain = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_APPLEDOMAIN_DOMAIN' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({ type: 'SUCCESS_APPLEDOMAIN_DOMAIN', message: 'Success get data' });
        }
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'Network error..' });
      });
  };
};

export const __deleteDataAppleDomain = (params) => {
  return (dispatch) => {
    const data = new FormData();
    data.append('domain', params.domain);
    let config = CONFIG_CP({
      url: '/internal/braintree/apple-pay/unregister-domain/',
      method: 'POST',
      data: data,
      headers: { sessionid: __userID('session_id') },
    });
    dispatch({ type: 'LOADING_APPLEDOMAIN_DOMAIN' });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status) {
          notifSuccess('Domain has been deleted!');
          return dispatch(__getDataAppleDomain());
        }
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_APPLEDOMAIN_DOMAIN', message: 'Network error..' });
      });
  };
};
