import { CONFIG_ } from '../config/Config';
import { CONNECTION } from '../config/Connection';

const endpoint = process.env.REACT_APP_PATH_AUTH;

export const refreshToken = (session_id) => {
  const config = CONFIG_({
    url: endpoint + 'refresh-token',
    headers: {
      session_id: session_id,
    },
  });
  return CONNECTION(config);
};
