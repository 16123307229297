import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Page404 from "../pages/error/page/Page_404";
export default class Blank extends Component {
  render() {
    return (
      <>
        <Router basename="/">
          <React.Suspense fallback>
            <Switch>
              <Route
                exact={true}
                name="Page Not Found"
                render={(props) => <Page404 {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </>
    );
  }
}
