import React, { Component } from 'react';
// import routers from "../config/Router";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../App.scss';
import Blank from '../layout/Blank';
import getRouteFromService from '../config/Router';
import { connect } from 'react-redux';
import { SET_CAN, SET_ROUTERS } from '../models/action-reducer-type';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import { GetPermissionBySessionID } from '../models/can/Can_act';
import { toast } from 'react-toastify';
import { getUserProfile } from '../models/user/Profile_act';
import { __getDataMenu } from '../models/apps/Menu_act';
import { refreshToken } from '../services/AuthServices';
import { __getDataGetIP } from '../models/GetIP/GetIP_act';

class Index extends Component {
  constructor(props) {
    super(props);

    let request_id = '';
    let session_id = '';
    let ipAddress = '';
    axios.interceptors.request.use((config) => {
      const cpurl = process.env.REACT_APP_API_INTERNAL;

      if (cpurl && config.url && config.url.indexOf(cpurl) > -1) return config;

      const apiurl = process.env.REACT_APP_ENDPOINT;
      if (apiurl && config.url && !config.url.indexOf(apiurl)) {
        if (request_id) {
          config.headers['gf-request-id'] = request_id;
        }
        if (session_id) {
          config.headers['session_id'] = session_id;
        }
      }

      if (ipAddress !== '') {
        config.headers['gf-client-ip'] = ipAddress;
      }

      return config;
    });
    axios.interceptors.response.use((value) => {
      if (value.headers['gf-request-id']) {
        request_id = value.headers['gf-request-id'];
      }

      return value;
    });

    //redirect if auth not valid
    const redirectToAuth = () => {
      const REDIRECT_AUTH = process.env.REACT_APP_REDIRECT_AUTH;
      const locationWindow = window.location.origin + '/dashboard';
      const appname = process.env.REACT_APP_NAME;
      window.location =
        REDIRECT_AUTH + '?redirect=' + btoa(locationWindow + '|' + appname) + '&mode=5';
    };

    // get previous saved session_id
    let session_storage = localStorage.getItem('session_id') || '';

    // get from param if available
    const query = new URLSearchParams(window.location.search);
    let session_param = query.get('session_id') || '';

    const __authenticate = (session_id) => {
      refreshToken(session_id)
        .then((res) => {
          if (res.code === 200) {
            getUserProfile(session_id)
              .then((res) => {
                const { code, data } = res;
                const appname = process.env.REACT_APP_NAME;
                if (code === 200) {
                  const { profile_accesses } = data[appname];

                  const role_id = profile_accesses ? profile_accesses[0].role_id : undefined;
                  //cek valid or not
                  GetPermissionBySessionID(session_id, role_id)
                    .then((res) => {
                      // console.log('result profile', res);
                      if (res.code === 200 && res.profile === true) {
                        localStorage.setItem('session_id', session_id);
                        // get menus application
                        this.props.__getDataMenu(role_id);

                        this.props.setRules(res.modules);

                        getRouteFromService().then((resRouters) => {
                          this.props.setRoute(resRouters);
                        });
                      } else {
                        toast.warning(res.message || 'You have no right to access this page', {
                          onClose: redirectToAuth,
                        });
                      }
                    })
                    .catch((err) => {
                      console.log('error get permission', err);
                      toast.error(
                        'Failed get permission for user, you will be redirected to auth page',
                        {
                          onClose: redirectToAuth,
                        }
                      );
                    });
                } else {
                  redirectToAuth();
                }
              })
              .catch((err) => {
                redirectToAuth();

                console.log(err);
              });
          } else {
            toast.warning(res.message || 'Your session is expired, please re-login!', {
              onClose: redirectToAuth,
            });
          }
        })
        .catch((err) => {
          console.log('error refresh token', err);
        });
    };

    if (session_storage || session_param) {
      if (session_storage) session_id = session_storage;
      //base64decode from param
      if (session_param) session_id = atob(session_param);
      __getDataGetIP()
        .then((response) => {
          const { ip } = response;
          ipAddress = ip;
          console.log(response);
          __authenticate(session_id);
        })
        .catch((response) => {
          console.log(response);
          __authenticate(session_id);
        });
      //remove search from url
      window.history.pushState(
        undefined,
        undefined,
        window.location.href.replace(window.location.search, '')
      );
    } else {
      redirectToAuth();
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot){
  //   console.log('prev Props, ', prevProps);
  //   console.log('this props', this.props);
  // }

  loading = () => <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>;

  render() {
    return (
      <>
        {this.props.modulRouters && this.props.modulRouters.routers.length > 0 ? (
          <Router basename='/'>
            <React.Suspense fallback={this.loading()}>
              <Switch>
                {this.props.modulRouters.routers.map((url, i) => {
                  return url.layout ? (
                    <Route
                      key={i}
                      path={url.path}
                      exact={url.exact}
                      name={url.name}
                      render={(props) => <url.layout {...props} role={url.role} />}
                    />
                  ) : null;
                })}
                <Route
                  exact={true}
                  name='Page Not Found'
                  render={(props) => <Blank {...props} />}
                />
              </Switch>
            </React.Suspense>
          </Router>
        ) : (
          <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ modulRouters, modulCan }) => ({
  modulRouters,
  modulCan,
});
const mapDispatchToProps = (dispatch) => ({
  setRoute: (params) => dispatch({ type: SET_ROUTERS, data: params, code: 200 }),
  setRules: (params) => dispatch({ type: SET_CAN, data: params, code: 200 }),
  __getDataMenu: (params) => [dispatch(__getDataMenu(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
