import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';
import { notifSuccess, notifWarning } from '../../component/notif/Notification';
import { __openModal } from '../modal/Modal_act';

var get = 'get';
var post = 'create';
var path = process.env.REACT_APP_DEFAULT_SETTING;

export const __getDataDefaultSetting = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: 'GET', path: path });
    dispatch({ type: 'LOADING_DEFAULTSETTING_DEFAULTSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_DEFAULTSETTING_DEFAULTSETTING',
            message: 'Success get data',
            data: data !== null ? data : [],
          });
        }
        return dispatch({ type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING',
          message: 'Network error..',
        });
      });
  };
};

export const __postDataDefaultSetting = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST', path: path });
    dispatch({ type: 'LOADING_DEFAULTSETTING_DEFAULTSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Default Setting has been Create!');
          dispatch(__openModal({ modal: 'MODAL_DEFAULT_SETTING', open: false }));
          return dispatch(__getDataDefaultSetting());
        }
        notifWarning('Somthing wrong!');
        return dispatch({ type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING',
          message: 'Network error..',
        });
      });
  };
};

export const __updateDataDefaultSetting = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: 'update/' + id, data: params, method: 'PUT', path: path });
    dispatch({ type: 'LOADING_DEFAULTSETTING_DEFAULTSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Default Setting has been Update!');
          dispatch(__openModal({ modal: 'MODAL_EDIT_DEFAULTSETTING', open: false }));
          return dispatch(__getDataDefaultSetting());
        }
        notifWarning('Somthing wrong!');

        return dispatch({ type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING',
          message: 'Network error..',
        });
      });
  };
};

export const __detailDataDefaultSetting = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'GET' });
    dispatch({ type: 'LOADING_DEFAULTSETTING_DEFAULTSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_DEFAULTSETTING_DEFAULTSETTING',
            message: 'Success get data',
          });
        }
        return dispatch({ type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING',
          message: 'Network error..',
        });
      });
  };
};

export const __deleteDataDefaultSetting = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: 'delete/' + id, method: 'DELETE', path: path });
    dispatch({ type: 'LOADING_DEFAULTSETTING_DEFAULTSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Default Setting has been Delete!');
          return dispatch(__getDataDefaultSetting());
        }
        notifWarning('Somthing wrong!');
        return dispatch({ type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_DEFAULTSETTING_DEFAULTSETTING',
          message: 'Network error..',
        });
      });
  };
};
