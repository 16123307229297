const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restDataCategoryInformation: [],
  code: 500,
  message: "",
};

let response = {};
const Categoryinformation_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_CATEGORYINFORMATION_FRAUD":
      response = {
        ...state,
        restDataCategoryInformation: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_CATEGORYINFORMATION_FRAUD":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_CATEGORYINFORMATION_FRAUD":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Categoryinformation_lib;
