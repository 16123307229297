import { ActTypeFormsets } from "./FormSet_act";

const defaultState = {
    restData : [],
    loadingPage : false,
    modalFormSetIsOpen: false,
    modalFormSetData: {},
    modalFormSetSend : false
}

const FormSet_lib = (state = defaultState, action) => {
    let newState = {...state};
    switch(action.type){
        case ActTypeFormsets.SUCCESS:
            newState.restData = action.data ? action.data : [];
            newState.loadingPage = false;
            break;
        case ActTypeFormsets.START_LOADING:
            newState.loadingPage = true;
            break;
        case ActTypeFormsets.FINISH_LOADING:
            newState.loadingPage = false;
            break;
        case ActTypeFormsets.MODAL_TOGGLE:
            newState.modalFormSetIsOpen = !state.modalFormSetIsOpen;
            newState.modalFormSetData = action.data ? action.data : {};
            break;
        case ActTypeFormsets.START_SEND:
            newState.modalFormSetSend = true;
            break;
        case ActTypeFormsets.FINISH_SEND:
            newState.modalFormSetSend = false;
            break;
        default:
    }
    return newState;
}

export default FormSet_lib;

