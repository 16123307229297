import { CONNECTION } from "../../config/Connection";
import { CONFIG_ } from "../../config/Config";

const PATH = process.env.REACT_APP_PATH_AUTH;
// const idApps = process.env.REACT_APP_ID;
const appName = process.env.REACT_APP_NAME;
// const get = PATH + "menus";

export const __getDataMenu = (roleID) => {
  return (dispatch) => {
    const config = CONFIG_({
      url: PATH + "permission-menus/" + appName+"/"+roleID,
      method: "GET",
    });
    dispatch({ type: "LOADING_MENU_APPS" });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: "SUCCESS_MENU_APPS",
            message: "Success get data",
            data: data.menus,
            code: code,
          });
        }
        return dispatch({ type: "ERROR_MENU_APPS", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_MENU_APPS",
          message: "Network error..",
        });
      });
  };
};
