import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';
import { notifSuccess } from '../../component/notif/Notification';
import { __openModal } from '../modal/Modal_act';

var get = 'get';
var post = 'create';
var isLive = false;

const PATH = process.env.REACT_APP_PATH_FORM_GENERATOR;
export const __getDataGeneralSetting = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: 'GET', path: PATH });
    dispatch({ type: 'LOADING_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_GENERALSETTING',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __postDataGeneralSetting = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST', path: PATH });
    dispatch({ type: 'LOADING_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Form Generator has been created');
          dispatch(__openModal({ modal: 'MODAL_ADD_GENERAL_SETTING', open: false }));
          return dispatch(__getDataGeneralSetting());
        }
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __updateDataGeneralSetting = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: 'update/' + id, data: params, method: 'PUT', path: PATH });
    dispatch({ type: 'LOADING_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Form Generator has been created');
          dispatch(__openModal({ modal: 'MODAL_EDIT_GENERAL_SETTING', open: false }));
          return dispatch(__detailDataGeneralSetting({ id: id }));
        }
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __detailDataGeneralSetting = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: get + '/' + id, method: 'GET', path: PATH });
    dispatch({ type: 'LOADING_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_GENERALSETTING_DETAIL',
            message: 'Success get data',
            data: {
              form_schema: {},
              ...data,
            },
          });
        }
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'Network error..' });
      });
  };
};

export const __deleteDataGeneralSetting = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: 'delete' + '/' + id, method: 'DELETE', path: PATH });
    dispatch({ type: 'LOADING_GENERALSETTING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Setting has been delete!');
          return dispatch(__getDataGeneralSetting());
        }
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_GENERALSETTING', message: 'Network error..' });
      });
  };
};
