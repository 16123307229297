import { SET_JOB_POSTINGS, ERROR_JOB_POSTINGS, SHOW_MODAL_JOB_POSTINGS, CLOSE_MODAL_JOB_POSTINGS, LOADING_DATA_JOB_POSTINGS, LOADING_SEND_JOB_POSTINGS } from "../../../models/action-reducer-type";

const defaultState = {
  loadingPage: false,
  loadingSend: false,
  data: [],
  error: null,
  modalFormIsOpen: false,
  modalFormData: {},
};

const JobPostings_lib = (state = defaultState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_JOB_POSTINGS:
      newState.data = action.data ? action.data : [];
      newState.loadingPage = false;
      newState.loadingSend = false;
      newState.error = null;
      newState.lastUpdate = new Date();
      break;
    case ERROR_JOB_POSTINGS:
      newState.error = action.error ? action.error : "UNKNOWN ERROR";
      newState.loadingPage = false;
      newState.loadingSend = false;
      break;
    case SHOW_MODAL_JOB_POSTINGS:
      newState.modalFormIsOpen = true;
      newState.modalFormData = action.data ? action.data : {};
      break;
    case CLOSE_MODAL_JOB_POSTINGS:
      newState.modalFormIsOpen = false;
      newState.modalFormData = {};
      break;
    case LOADING_DATA_JOB_POSTINGS:
      newState.loadingPage = true;
      break;
    case LOADING_SEND_JOB_POSTINGS:
      newState.loadingSend = true;
      break;
    default:
      newState = {...state}
      break;
  }
  return newState;
};

export default JobPostings_lib;
