import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';

var get = '';
var post = '';
export const __getDataMandrill_list = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: 'mandrill/list', method: 'POST', path: 'custom-email-sender/' });
    dispatch({ type: 'LOADING_MANDRILL_LIST_MANDRILL-MONITORING' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_MANDRILL_LIST_MANDRILL-MONITORING',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING',
          message: 'Network error..',
        });
      });
  };
};

export const __postDataMandrill_list = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST' });
    dispatch({ type: 'LOADING_MANDRILL_LIST_MANDRILL-MONITORING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          return dispatch({
            type: 'SUCCESS_MANDRILL_LIST_MANDRILL-MONITORING',
            message: 'Success post data',
          });
        }
        return dispatch({ type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING',
          message: 'Network error..',
        });
      });
  };
};

export const __updateDataMandrill_list = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, data: params, method: 'PUT' });
    dispatch({ type: 'LOADING_MANDRILL_LIST_MANDRILL-MONITORING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_MANDRILL_LIST_MANDRILL-MONITORING',
            message: 'Success put data',
          });
        }
        return dispatch({ type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING',
          message: 'Network error..',
        });
      });
  };
};

export const __detailDataMandrill_list = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({
      url: 'mandrill/info',
      method: 'POST',
      path: 'custom-email-sender/',
      data: params,
    });
    dispatch({ type: 'LOADING_MANDRILL_LIST_MANDRILL-MONITORING' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'DETAIL_MANDRILL_LIST_MANDRILL-MONITORING',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING',
          message: 'Network error..',
        });
      });
  };
};

export const __deleteDataMandrill_list = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + '/' + id, method: 'DELETE' });
    dispatch({ type: 'LOADING_MANDRILL_LIST_MANDRILL-MONITORING' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_MANDRILL_LIST_MANDRILL-MONITORING',
            message: 'Success get data',
          });
        }
        return dispatch({ type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_MANDRILL_LIST_MANDRILL-MONITORING',
          message: 'Network error..',
        });
      });
  };
};
