const { SET_CAN } = require("../action-reducer-type")

const defaultState = {
    code : null,
    data : {}
}

const Can_lib = (state=defaultState, action={}) => {
    let response;
    switch(action.type){
        case SET_CAN:
            response = {
                ...state, 
                data : action.data ? action.data : {},
                code : action.code ? action.code : null
            }
            break;
        default:
            response = {...state}
    }
    return response;
}

export default Can_lib