const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: [],
  detail: {},
  code: 500,
  message: '',
  status: 'default',
  id: '',
};

let response = {};
const Mandrill_subaccount_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_MANDRILL_SUBACCOUNT_MANDRILL-MONITORING':
      response = {
        ...state,
        restData: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        status: 'success',
        message: action.message ? action.message : '',
      };

      break;
    case 'DETAIL_MANDRILL_SUBACCOUNT_MANDRILL-MONITORING':
      response = {
        ...state,
        detail: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        status: 'success',
        message: action.message ? action.message : '',
      };

      break;
    case 'LOADING_MANDRILL_SUBACCOUNT_MANDRILL-MONITORING':
      response = {
        ...state,
        loadingPage: true,
        id: action.id ? action.id : '',
        loadingSend: true,
        status: 'loading',
      };
      break;
    case 'ERROR_MANDRILL_SUBACCOUNT_MANDRILL-MONITORING':
      response = {
        ...state,
        status: 'success',
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Mandrill_subaccount_lib;
